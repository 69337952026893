
    * {
        font-family: poppins;
        box-sizing: border-box;
    }
    .pinOutline { top: 0; left: 0; position: absolute };
    .multiplePinsOutline { left: 0; top: 0; position: absolute };
    .container  {
      position: relative;
      width: 30px;
      height: 42px;
    }
    .containerMultiple {
      position: relative;
      width: 42px;
      height: 42px;
    }
    .svg {
      position: absolute;
      top: 5px;
      left: 8px;
      fill: white;
      stroke: white;
      filter: invert(95%) sepia(100%) saturate(0%) hue-rotate(180deg) brightness(103%) contrast(108%);
    }

    .white {
        filter: invert(95%) sepia(100%) saturate(0%) hue-rotate(180deg) brightness(103%) contrast(108%);
    }
    .custom-pin {
        position: absolute;
        top: 5px;
        left: 7px;
    }
    
    .number {
      position: absolute;
      color: white;
      font-size: 16px;
      width: 30px;
      height: 42px;
      text-align: center;
      padding-top: 3px;
      left: 0;
    }

    .overlay {
        width: 100vw;
        height: 100vh;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        pointer-events: none;
        /* opacity: 0.4; */
        /* background-color: black; */
        /* z-index: 99999; */
    }

    html, body {
        padding: 0;
        margin: 0;
        width: 100vw;
        height: 100vh;
        overflow: hidden;
    }

    .radius {
        position: absolute;
        top: 0;
        left: 25px;
        font-size: 18px;
        color:white;
    }


    .fade {
        animation-name: fade;
        animation-fill-mode: both;
        animation-duration: 0.3s;
    }
    .bounce {
        animation-name: bounce;
        animation-fill-mode: both;
        animation-duration: 1s;
      }

      @keyframes fade {
        0% {
          opacity: 0;

        }
      
        100% {
          opacity: 1;
        }
      }

@keyframes bounce {
    0% {
      opacity: 0;
      transform: translateY(-2000px);
    }
  
    60% {
      opacity: 1;
      transform: translateY(30px);
    }
  
    80% {
      transform: translateY(-10px);
    }
  
    100% {
      transform: translateY(0);
    }
  }


  .sidebar {
    position: fixed;
    top: 0;
    left:0;
    bottom:0;
    height: 100%;
    transition: 0.5s;
    width: 0;
    z-index: 1000;
    background-color: white;
    overflow-y: auto;
    overflow-x: hidden;
    box-shadow: -3px 0 5px 0 #555;
  }

  .icon-and-text {
    display: inline-flex;
    vertical-align: middle;
  }

  .icon-and-text > svg {
    margin-right: 6px;
  }

 .open {
    width: min(450px, 90vw);
  }

  .close {
    position: absolute;
    top: 15px;
    right: 15px;
    font-family: monospace;
    font-size: 24px;
    border: none;
    background-color:white;
    cursor: pointer;
  }

  a {
    color: #138601;
  }
  .venue-content {
    padding: 25px;
  }

  .venue-list {
    padding-top: 25px;
  }
  .event-list-item {
    border-radius: 15px;
    margin-bottom: 25px;
  }
  .back {
    top: 15px;
    left: 15px;
    position: absolute;
    border: none;
    background-color: white;
  }

  .view {
    cursor: pointer;
  }

  .category-name {
    display: flex;
  }

  .card-container {
    padding: 25px;
  }
  .card {
    /* Add shadows to create the "card" effect */
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
  }
  
  /* On mouse-over, add a deeper shadow */
  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }

  .button {
    padding: 10px 25px;
    font-size: 18px;
    width: 100%;
    background-color: #138601;
    border: none;
    color: white;
    border-radius: 6px;
    display: block;
    text-decoration: none;
    text-align: center;
    
  }

  h1 {
    font-size: 20px;
  }

  h2 {
    font-size: 18px;
  }

  h3 {
    font-size: 16px;
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom:0;
    background-color: black;
    opacity: 0.4;
    z-index: 9999;
 
  }

  .modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom:0;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;

  }

  .modal {
    background-color: white;
    padding: 25px;
    border-radius: 5px;
    position: relative;
  }

  .download-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
